<template>
  <div id="abnormal">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">近一周异常趋势</span>
          <dv-decoration-1 style="width:1.25rem;height:.25rem;position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center flex-1 h-100">
        <abnormalChart :cdata="cdata" />
      </div>
    </div>
  </div>
</template>

<script>
import abnormalChart from "@/components/echart/abnormalChart";
export default {
  data() {
    return {
      cdata: {
        category: [],
        bar1Data: [],
        bar2Data: [],
        bar3Data: [],
        rateData: []
      }
    };
  },
  components: {
    abnormalChart
  },
  mounted() {
    
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    data: {
      handler(newData) {
        var category = [];
        var bar1Data = [];
        var bar2Data = [];
        var bar3Data = [];
        var rateData = [];
        newData.forEach(element => {
          category.push(`${Number(element.time.split('/')[0])}月${Number(element.time.split('/')[1])}日`);
          bar1Data.push(element.totalFireAlarmDeviceCount);
          bar2Data.push(element.totalMalfunctionDeviceCount);
          bar3Data.push(element.totalOfflineDeviceCount);
          rateData.push(element.growthRate);
        });
        this.cdata.category = category;
        this.cdata.bar1Data = bar1Data;
        this.cdata.bar2Data = bar2Data;
        this.cdata.bar3Data = bar3Data;
        this.cdata.rateData = rateData;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
#abnormal {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    width: 100%;
    height: 100%;
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
}
</style>