<template>
  <div id="alarmRankList">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-justify" scale="0.8"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">{{isAdmin ? '近三十天各项目警情排名统计' : '近一周各楼宇警情排名统计'}}</span>
        </div>
        <span class="text">报警/预警</span>
      </div>
      <div class="d-flex jc-center flex-1 body-box">
        <dv-scroll-board ref="alarmRankRef" :config="config" style="width:100%;height:100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        // header: ["排名", "区域楼宇名称", "数量"],
        header: ["排名", "区域项目名称", "数量"],
        data: [],
        headerHeight: 25,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        rowNum: 3,
        index: false,
        columnWidth: [100,320,100],
        align: ["center", "center", "center"],
        hoverPause: true,
        waitTime: 2000
      }
    };
  },
  components: {},
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    rowNum: {
      type: Number,
      default: 3
    }
  },
  watch: {
    rowNum: {
      handler(newData) {
        var obj = JSON.parse(JSON.stringify(this.config));
        obj.rowNum = newData;
        this.config = JSON.parse(JSON.stringify(obj));
      },
      deep: true,
      immediate: true
    },
    list: {
      handler(newData) {
        var arr = [];
        newData.forEach((element, index) => {
          arr.push([
            `<span style='color:#11d0c9;'>${index+1}</span>`,
            `<div class='d-flex jc-center ai-center w-100 h-100'><span class='alarm-rank-address' style='color:#11d0c9;'>${element.tower}</span></div>`, 
            `<span style='color:#11d0c9;'>${element.totalEventCount}</span>`
          ]);
        });
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.alarmRankRef.updateRows(arr);
          }, 0);
        });
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
.alarm-rank-address {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
</style>
<style lang="scss" scoped>
#alarmRankList {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  .bg-color-black {
    width: 100%;
    height: 100%;
  }
  .text {
    font-size: 0.15rem;
    color: #11d0c9;
  }
  .body-box {
    height: 100%;
    overflow: hidden;
  }
}
</style>